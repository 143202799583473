export const MAINNET_CHAINS = {
  1: {
    urls: "https://mainnet.infura.io/v3/331349daece4478d963268500f679d7f",
    name: "ETH Mainnet",
  },
  56: {
    urls: "https://bsc-dataseed1.binance.org/",
    name: "Bsc",
    nativeCurrency: "BNB",
    blockExplorerUrls: ["https://bscscan.com/"],
  },
};

export const optionalChains = {
  97: {
    urls: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    name: "Bsc Testnet",
    nativeCurrency: "TBNB",
    blockExplorerUrls: ["https://testnet.bscscan.com/"],
  },
};

export const CHAINS = { ...MAINNET_CHAINS, ...optionalChains };
