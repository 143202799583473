import { initializeConnector } from "@web3-react/core";
import { WalletConnect as WalletConnectV2 } from "@web3-react/walletconnect-v2";

import { MAINNET_CHAINS, optionalChains } from "./Chains";

const MAIN_NET_KEY = Object.keys(MAINNET_CHAINS);
const keysTestnet = Object.keys(optionalChains);

const rpcObjs = {};
for (let i = 0; i < MAIN_NET_KEY.length; i++) {
  rpcObjs[Number(MAIN_NET_KEY[i])] = MAINNET_CHAINS[MAIN_NET_KEY[i]].urls;
}

for (let i = 0; i < keysTestnet.length; i++) {
  rpcObjs[Number(keysTestnet[i])] = optionalChains[keysTestnet[i]].urls;
}

console.log(rpcObjs);

export const [walletConnectV2, hooks] = initializeConnector(
  (actions) =>
    new WalletConnectV2({
      actions,
      options: {
        projectId: "0d9f72c62b351ce6fce2cb657051ab96",
        chains: [MAIN_NET_KEY[0]],
        optionalChains: [MAIN_NET_KEY.slice(1), ...keysTestnet],
        showQrModal: true,
        rpcMap: {
          ...rpcObjs,
        },
      },
    })
);
