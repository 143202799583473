import { toast } from "react-toastify";

export const showToast = (type, message) => {
  if (type === "success") {
    toast.success(message, {
      position: "bottom-left",
      autoClose: 3000,
      hideProgressBar: true,
      pauseOnFocusLoss: true,
    });
  }
  if (type === "error") {
    toast.error(message, {
      position: "bottom-left",
      autoClose: 3000,
      hideProgressBar: true,
    });
  }
  if (type === "info") {
    toast.info(message, {
      position: "bottom-left",
      autoClose: 3000,
      hideProgressBar: true,
    });
  }
};
