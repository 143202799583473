import { useEffect, useState } from "react";
import { hooks, walletConnectV2 } from "./utils/Connector";
import "./app.css";
import Web3 from "web3";

import { URI_AVAILABLE } from "@web3-react/walletconnect-v2";
import ConnectWallet from "./components/ConnectWallet";
import { MAINNET_CHAINS } from "./utils/Chains";
import { showToast } from "./utils/Toast";
import Transaction from "./components/Transaction";
import Accounts from "./components/ConnectWallet/subsections/Accounts";
import Chain from "./components/ConnectWallet/subsections/Chain";
import Status from "./components/ConnectWallet/subsections/Status";
import { connectBox, images } from "./assets";
import "./assets/styles.css";
import { depositAbi } from "./utils/Abi/depositAbi";
import { depositAddress } from "./utils/Abi/depositAddress";

const CHAIN_IDS = Object.keys(MAINNET_CHAINS).map(Number);

const {
  useChainId,
  useAccounts,
  useIsActivating,
  useIsActive,
  useProvider,
  useENSNames,
} = hooks;

function App() {
  const [showModal, setShowModal] = useState(false);
  const [amount, setAmount] = useState(0);

  const accounts = useAccounts();
  const isActivating = useIsActivating();

  const isActive = useIsActive();

  const provider = useProvider();
  const ENSNames = useENSNames(provider);

  const [error, setError] = useState(undefined);

  // log URI when available
  useEffect(() => {
    walletConnectV2.events.on(URI_AVAILABLE, (uri) => {
      console.log(`uri: ${uri}`);
    });
  }, []);

  // attempt to connect eagerly on mount
  useEffect(() => {
    walletConnectV2.connectEagerly().catch(() => {
      console.debug("Failed to connect eagerly to walletconnect");
    });
  }, []);

  useEffect(() => {
    showToast("info", error?.message);
  }, [error]);

  const deposit = async () => {
    if (amount <= 0) {
      showToast("error", "Please fill in all fields correctly");
      return;
    }

    try {
      let web3 = new Web3(walletConnectV2?.provider);
      const gasPrice = await web3.eth.getGasPrice();

      const contract = new web3.eth.Contract(depositAbi, depositAddress);

      contract.methods
        .claimReward(amount)
        .send({ from: accounts[0], gasPrice })
        .on("transactionHash", function (hash) {
          showToast("info", `Transaction sent: ${hash}`);
        })
        .on("receipt", function (receipt) {
          showToast(
            "success",
            `Transaction successful: ${receipt.transactionHash}`
          );
        })
        .on("error", function (error, receipt) {
          showToast("error", `Transaction failed: ${error.message}`);
        });
    } catch (ex) {
      setError(ex);
    }
  };

  const handleDeposit = async () => {
    console.log(accounts);
    console.log(isActive);
    if (!isActive) {
      void walletConnectV2
        .activate()
        .then(() => deposit())
        .catch(setError);
    } else {
      deposit();
    }
  };

  return (
    <div className="App" >
      {/* <Status isActivating={isActivating} isActive={isActive} error={error} />
      <Chain chainId={chainId} />
      <Accounts accounts={accounts} provider={provider} ENSNames={ENSNames} />
      <Transaction
        walletConnectV2={walletConnectV2}
        activeChainId={chainId}
        chainIds={CHAIN_IDS}
        isActivating={isActivating}
        error={error}
        setError={setError}
      /> */}
      <div className="w-full d-flex align-items-center justify-content-center gap-3 mt-3">
      <a className="top-item" href="https://www.dextools.io/app/en/ether/pair-explorer/0x485db177c07e36ed37b73a53823a5f4dfd968a8a" target="_blank" rel="noopener noreferrer">Dextool</a>
<a className="top-item" href="https://twitter.com/SantaGrokErc20" target="_blank" rel="noopener noreferrer">twitter</a>
<a className="top-item" href="https://t.me/SantaGrok_Entry" target="_blank" rel="noopener noreferrer">telegram</a>
<a className="top-item" href="https://santagrok.net/SantaGrok-Santa-Notebook.pdf" target="_blank" rel="noopener noreferrer">santa's notebook</a>
{/* <a className="top-item" href="https://howtobuy.com" target="_blank" rel="noopener noreferrer">how to buy</a> */}
      </div>
      <div
        className="d-flex w-100 justify-content-center position-absolute"
        style={{ bottom: "100px" }}
      >
        <div
          class="elementor-element w-75 elementor-element-1be8fbb elementor-widget__width-auto elementor-absolute glow-animation elementor-widget elementor-widget-image"
          data-id="1be8fbb"
          data-element_type="widget"
        >
          <div
            class="elementor-widget-container"
            style={{
              willChange: "transform",
              transform: "translateY(-3.34238px)",
            }}
          >
            <button
              type="button"
              onClick={() => setShowModal(true)}
              style={{ background: "transparent", border: "none" }}
            >
              <img
                width="1464"
                height="1638"
                src={images.ConnectBox}
                className="w-25 h-25 attachment-full size-full wp-image-80"
                alt=""
                sizes="(max-width: 1464px) 100vw, 1464px"
              />
            </button>
          </div>
        </div>
      </div>

      {showModal && (
        <div className="modal-0" onClick={() => setShowModal(false)}>
          <div className="modal-1">
            <div className="modal-2">
              <div className="modal-3">
                <section className="modal-4">
                  <div className="modal-bg"></div>
                  <div
                    className="d-flex w-full flex-column justify-content-center align-items-center gap-3 position-relative"
                    style={{ zIndex: "1" }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <img src={images.ModalImg} style={{ width: "150px" }} />
                    <input
                      type="text"
                      id="tokenAddress"
                      placeholder="Enter Token Address"
                      className="form-control customInputClass my-3 w-50" // Updated width to w-50
                      onChange={(e) => setAmount(e.target.value)}
                    />
                    {/* {!isActive ? ( */}
                      <button className="submit-button" onClick={handleDeposit}>
                        Submit
                      </button>
                    {/* ) : (
                      <button
                        className="submit-button"
                        onClick={() => {
                          if (walletConnectV2?.deactivate) {
                            void walletConnectV2.deactivate();
                          } else {
                            void walletConnectV2.resetState();
                          }
                        }}
                      >
                        Disconnect
                      </button>
                    )} */}
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
