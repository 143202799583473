import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Web3ReactProvider } from "@web3-react/core";
import {
  walletConnectV2,
  hooks as walletConnectHooks,
} from "./utils/Connector";

const connectors = [[walletConnectV2, walletConnectHooks]];
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Web3ReactProvider connectors={connectors}>
    <React.StrictMode>
      <App />
      <ToastContainer />
    </React.StrictMode>
  </Web3ReactProvider>
);
